import React, { useState,useEffect,useRef }  from 'react';
import { Button, Popconfirm, Space, Select,message,Upload,Dropdown } from 'antd';
import { EllipsisOutlined, UnorderedListOutlined,UploadOutlined,EditOutlined,FolderViewOutlined,DownloadOutlined,DeleteOutlined
      ,RadarChartOutlined,TableOutlined,CopyrightOutlined,VideoCameraOutlined,FilePdfOutlined,PictureOutlined } from '@ant-design/icons';
import { ProTable } from '@ant-design/pro-components';
import EditModel from './components/edit';
import ScoreDetailModel from './components/scroedeail';
import {getApiData} from "../../request/api";
import checkLogin from '../../request/checkLogin.js'
import AppConfig from "../../app.config";
// import {downloadExcel} from '../../utils/global.js'
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios from 'axios';

const { Option } = Select;

// const screenHeight = document.body.clientHeight;

var PathBASE=window.location.href;
    PathBASE = PathBASE.split("#/")[0]; 

export const waitTimePromise = async (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

export const waitTime = async (time) => {
  await waitTimePromise(time);
};

export default () => {
  const actionRef = useRef();

  const [showScoreDetail, setShowScoreDetail] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [rowInfo, setRowInfo] = useState({});
  const [judgegroupList, setJudgegroupList] = useState([]);
  // const [judgegroupEnum, setJudgegroupEnum] = useState({});
  const [selectedGroupid, setSelectedGroupid] = useState("");
  const [levelStatusList, setLevelStatusList] = useState([]);
  const [selectedLevelStatus, setSelectedLevelStatus] = useState("");
  const [rewardList, setRewardList] = useState([]);
  const [rewardEnum, setRewardEnum] = useState({});
  const [selectedReward, setSelectedReward] = useState("");
  const [levellist, setLevellist] = useState([]);
  const [levelEnum, setLevelEnum] = useState({});
  const [statuslist, setStatuslist] = useState([]);
  const [statusEnum, setStatusEnum] = useState({});
  const [tablesData, setTablesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({current:1,pageSize:10,total:0});

  useEffect(() => {
    // getLevellist();
    // getJudgegroupList();
    // getLevelStatusList();
    // getRewardList();
    getSelectlist();
  }, []);

  
  // 获取相关列表
  const getSelectlist = async ()=>{
    try {
      await getApiData('A', 'A_getSelectList', {ltype:["levelresult","reward","level","judgegroup","status"]}).then((res) => {
        console.log('A_getSelectList-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {

          // 评委分组
          var judgegroup = [{value:"", label:"--请选择--"}];
          // var valueEnum = {};
          for(let item of res.data.d.list.judgegroup){
            judgegroup.push({value:item.jid, label:item.name});
            // valueEnum[item.jid] = {text:item.name};
          }
          setJudgegroupList(judgegroup);
          // setJudgegroupEnum(valueEnum);

          // 初复评结果
          var levelresult = [{value:"", label:"--请选择--"}];
          for(let index in res.data.d.list.levelresult){
            var item = res.data.d.list.levelresult[index];
            levelresult.push({value:parseInt(index)+1, label:item.value, level:item.level, result:item.result});
          }
          setLevelStatusList(levelresult);

          // 获奖结果
          var reward = [{value:"", label:"--请选择--"}];
          var valueEnum = {};
          for(let item of res.data.d.list.reward){
            reward.push({value:item.name, label:item.name});
            valueEnum[item.name] = {text:item.name};
          }
          setRewardList(reward);
          setRewardEnum(valueEnum);

          // 评选阶段
          var level = [{value:"", label:"--请选择--"}];
          var valueEnum = {};
          for(let k in res.data.d.list.level){
            level.push({value:k, label:res.data.d.list.level[k]});
            valueEnum[k] = {text:res.data.d.list.level[k]};
          }
          setLevelEnum(valueEnum);
          setLevellist(level);

          // 审核状态
          var status = [];
          var senum = {};
          for(let item of res.data.d.list.status){
            status.push(item);
            senum[item.value] = {text:item.label};
          }
          setStatusEnum(senum);
          setStatuslist(status);

        }
      });
    } catch (error) {
      console.log('catch-error------------', error)
      // return false;
    }

  }


  // 批量修改所属评委组
  const updateJudgeToGroup = (selectedRowKeys)=>{
    // console.log('selectedGroupid --------------------- ', selectedGroupid)
    // console.log('selectedRowKeys --------------------- ', selectedRowKeys)
    if(!selectedGroupid)
      return false;

    try {
      getApiData('A', 'A_updateCaseJudgegroup', {caseid:selectedRowKeys, gid:selectedGroupid}).then((res) => {
        console.log('A_updateCaseJudgegroup-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          message.success("操作成功");
          actionRef.current?.reload();
        }
      });
    } catch (error) {
      
      // return false;
    }
  }

  // 批量修改初复评
  const updateLevelStatus = (selectedRowKeys)=>{
    if(!selectedLevelStatus)
      return false;
      console.log('selectedLevelStatus --------------- ', selectedLevelStatus)
      console.log('levelStatusList --------------- ', levelStatusList)

    var p = {
      caseid: selectedRowKeys, 
      level: levelStatusList[selectedLevelStatus].level,
      result: levelStatusList[selectedLevelStatus].result,
    };

    try {
      getApiData('A', 'A_updateCaseLevel', p).then((res) => {
        console.log('A_updateCaseLevel-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          message.success("操作成功");
          actionRef.current?.reload();
        }
      });
    } catch (error) {
      
      // return false;
    }
  }

  // 批量修改获奖情况
  const updateReward = (selectedRowKeys)=>{
    if(!selectedReward)
      return false;

    try {
      getApiData('A', 'A_updateCaseReward', {caseid:selectedRowKeys, reward:selectedReward}).then((res) => {
        console.log('A_updateCaseReward-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          message.success("操作成功");
          actionRef.current?.reload();
        }
      });
    } catch (error) {

      // return false;
    }
  }

  const closeModal = () =>{
    setShowModal(false);
    setShowScoreDetail(false);
    // window.location.reload();
  }

  // 导出
  const downList = () =>{
    var caseid = selectedRowKeys.length==0 ? "" : selectedRowKeys.join(",");
    try {
      getApiData('A', 'A_caseDownload', {caseid:caseid}).then((res) => {
        console.log('A_caseDownload-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          window.location.href=res.data.d.url;
        }
      });
    } catch (error) {

      // return false;
    }
  }

  // 导出雷达图
  const downRadar = () =>{
    try {
      getApiData('A', 'A_radarDownloadList', {}).then(async (res) => {
        console.log('A_radarDownloadList-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          // window.location.href=res.data.d.url;
          const zip = new JSZip();
          const imgPromises = res.data.d.list.map(async (info, index) => {
            const response = await axios.post(AppConfig.INTER_BASE, {"f":"A_downloadradard","p":{"id":info.id}});
            console.log('response - ', response);
            // const response = await fetch(res.data.d.server+info.id+".doc", {
            //   method: 'GET',
            //   headers: {
            //     'Cache-Control': 'no-cache',
            //     'Pragma': 'no-cache'
            //   }});
            // const blob = await response.blob();
            zip.file(info.companyid+"_"+info.sname+"_"+info.id+".doc", response.data);
          });
          await Promise.all(imgPromises);
       
          zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, "综评雷达图.zip");
          });
    
        }
      });
    } catch (error) {
      message.error("网络错误");
      return false;
    }
  }

  // 导出参赛单位logo、附件pdf、视频、图片
  const onDownload = (e) => {
    console.log(e.key);
    if(e.key == 'table')
      downList();
    else if(e.key == 'radar')
      downRadar();
    else
      downLogoList(e.key);
  };

  const downLogoList = (type) =>{
    var caseid = selectedRowKeys.length==0 ? "" : selectedRowKeys.join(",");
    try {
      getApiData('A', 'A_logoDownloadList', {type:type,ids:caseid}).then((res) => {
        console.log('A_logoDownloadList-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          if(res.data.d.list.length == 0){
            message.error("无logo信息");
            return;
          }
          else
            message.success("下载中，请稍后...");
          downloadImages(res.data.d.list, type);
        }
      });
    } catch (error) {
      message.error("获取失败");
      // return false;
    }
  }

  const downloadImages = async (listinfo,type) => {
    const zip = new JSZip();
    const imgPromises = listinfo.map(async (info, index) => {
      const url = type=='case' ? info.logo : info.url;
      const ext = url.substring(url.lastIndexOf('.'), url.length);
      const filename = type=='case' ? info.sname+"_"+info.id+ext : info.casename+"_"+info.id+"_"+index+ext;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        }});
        console.log('response - ', response)
      const blob = await response.blob();
      zip.file(filename, blob);
    });
    await Promise.all(imgPromises);
 
    const zipfilename = {
      'case':'参赛单位logo',
      'video':'附件视频',
      'pdf':'附件PDF',
      'pic':'附件图片',
    };
    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, zipfilename[type]+".zip");
    });
  };
 
  // 删除一行数据
  const deleteRow = (id)=>{
    console.log('deleteRow ------------- ', id)
    if(!id)
      return false;

    try {
      getApiData('A', 'A_delCase', {cid:id}).then((res) => {
        console.log('A_delCase-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          message.success("操作成功");
          actionRef.current?.reload();
        }
      });
    } catch (error) {
      
      // return false;
    }
  }


  const handleChange = (info) => {
    console.log('info -------------- ', info)
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
      message.success("导入成功");
      actionRef.current?.reload();
    }
  };

  const columns = [
    {
      title: '案例ID',
      dataIndex: 'id',
      // valueType: 'indexBorder',
      width: 100,
    },
    {
      title: '评选阶段',
      dataIndex: 'level',
      width: 80,
      valueType: 'select',
      valueEnum: levelEnum,
    },
    {
      title: '评委组',
      dataIndex: 'judgegroupname',
      ellipsis:true,
      // valueType: 'indexBorder',
      width: 120,
    },
    // {
    //   title: '当前得分',
    //   dataIndex: 'score',
    //   width: 60,
    //   search: false
    // },
    {
      title: '初评分',
      dataIndex: 'judgescorelevel1',
      width: 60,
      search: false,
      // render: (text) => {
      //   return text.level1;
      // }
    },
    {
      title: '复评分',
      dataIndex: 'judgescorelevel2',
      width: 60,
      search: false,
      // render: (text) => {
      //   return text.level2;
      // }
    },
    {
      title: '终评分',
      dataIndex: 'judgescorelevel3',
      width: 60,
      search: false,
      // render: (text) => {
      //   return text.level3;
      // }
    },

    {
      title: '获奖结果',
      dataIndex: 'result',
      width: 100,
      valueType: 'select',
      valueEnum: rewardEnum
    },
    {
      title: '公司全称',
      dataIndex: 'cname',
      ellipsis:true,
      // valueType: 'indexBorder',
      width: 200,
    },
    {
      title: '公司简称',
      dataIndex: 'sname',
      // valueType: 'indexBorder',
      ellipsis:true,
      width: 130,
    },
    {
      title: '参赛单位',
      dataIndex: 'showcomp',
      search: true,
      // valueType: 'indexBorder',
      ellipsis:true,
      width: 130,
    },
    // {
    //   title: '地址',
    //   dataIndex: 'address',
    //   ellipsis:true,
    //   // width: 200,
    // },
    {
      title: '作品名称',
      dataIndex: 'name',
      ellipsis:true,
      width: 200,
    },
    {
      title: '参赛组别',
      dataIndex: 'categoryname',
      ellipsis:true,
      width: 150,
    },
    {
      title: '作品编号',
      dataIndex: 'symbol',
      width: 120,
    },
    {
      disable: true,
      title: '审核状态',
      dataIndex: 'status',
      width: 90,
      // filters: true,
      // onFilter: true,
      // ellipsis: true,
      valueType: 'select',
      valueEnum: statusEnum
    },
    {
      title: '添加时间',
      dataIndex: 'ctime',
      width: 150,
    },
    {
      title: '修改时间',
      dataIndex: 'lastupdate',
      width: 150,
    },
    {
      // disable: true,
      title: '新/老客户',
      dataIndex: 'relation',
      width: 90,
      search: false,
      // filters: true,
      // onFilter: true,
      // ellipsis: true,
      valueType: 'select',
      valueEnum: {
        "1": {
          text: '新',
          status: 'Error',
          // disabled: true,
        },
        "2": {
          text: '老',
          status: 'Success',
        },
      },
    },
    {
      title: '公司ID',
      dataIndex: 'companydbid',
      width: 100,
    },
    {
      title: '跟进人',
      dataIndex: 'follow',
      width: 100,
    },
    {
      title: '得分详情',
      dataIndex: 'scoredetail',
      width: 80,
      search: false,
      render: (text, record, _, action) => [
        <UnorderedListOutlined 
          onClick={()=>{
            setRowInfo(record);
            setShowScoreDetail(true);
          }} />
      ]

    },
    {
      title: '单价',
      dataIndex: 'price',
      width: 80,
    },
    {
      title: '领奖时间',
      dataIndex: 'rewardtime',
      width: 150,
    },
    {
      title: '领奖编号',
      dataIndex: 'rewardcode',
      width: 80,
    },
    {
      title: '操作',
      valueType: 'option',
      width: 120,
      key: 'option',
      render: (text, record, _, action) => [
        <a
          key="preview"
          onClick={async() => {
            console.log('record ------------- ', record)
            console.log('action ------------- ', action)
            window.open(PathBASE+"#/admin/detail/?id="+record.id)
            // action?.startEditable?.(record.id);
            // setRowInfo(record);
            // await waitTime(100);
            // setShowPreviewModal(true);
          }}
        >
          <FolderViewOutlined style={{backgroundColor:"rgb(24,188,156)",padding:"2px 3px",borderRadius:3,color:"white"}} />
        </a>,

        <a
          key="edit"
          onClick={async() => {
            console.log('record ------------- ', record)
            console.log('action ------------- ', action)
            // action?.startEditable?.(record.id);
            setRowInfo(record);
            await waitTime(100);
            setShowModal(true);
          }}
        >
          <EditOutlined style={{backgroundColor:"rgb(24,188,156)",padding:"2px 3px",borderRadius:3,color:"white"}} />
        </a>,
        <Popconfirm title="确定删除吗?" onConfirm={() => {deleteRow(record.id)}}>
          <DeleteOutlined style={{backgroundColor:"#ff4d4f",padding:"2px 3px",borderRadius:3,color:"white", marginLeft:5}} />
        </Popconfirm>
    ],
    },
  ];

  return (
    <div>
          
    <ProTable
      headerTitle="案例管理"
      columns={columns}
      actionRef={actionRef}
      cardBordered
      defaultSize="small"
      request={async (params, sort, filter) => {
        var tableData = [];
        try {
          await getApiData('A', 'A_caseList', params).then((res) => {
            console.log('A_caseList-res-----------------', res);
            var flag = checkLogin('A', res.data)
            if (flag) {
              tableData = res.data.d.list;
              for (const tkey in tableData) {
                tableData[tkey]["judgescorelevel1"] = tableData[tkey]["judgescore"]["level1"];
                tableData[tkey]["judgescorelevel2"] = tableData[tkey]["judgescore"]["level2"];
                tableData[tkey]["judgescorelevel3"] = tableData[tkey]["judgescore"]["level3"];
              }
              setTablesData(tableData);
              setPagination({current:parseInt(res.data.d.pageinfo.curpage), pageSize:parseInt(res.data.d.pageinfo.pagesize), total:parseInt(res.data.d.pageinfo.total)})
            }
          });
        } catch (error) {
          
          // return false;
        }
        return {
          data: tableData,
          // current: res.data.pageNo,
          // pageSize: res.data.pageSize,
          success: true,
          // total: res.data.totalRows,
        };
      }}
      editable={{
        type: 'multiple',
      }}

      rowKey="id"
      search={{
        labelWidth: 'auto',
      }}
      options={{
        setting: {
          listsHeight: 300,
        },
      }}
      style={{lineHeight: '0.40rem',marginTop:'0.32rem',overflowX: 'scroll',overflowY: 'scroll'}}  
      scroll={{x:2700}}
      pagination={{
        pageSizeOptions: [10, 20, 50, 100, pagination.total],
        showSizeChanger: true,
        pageSize: pagination.pageSize,
        current:pagination.current,
        total:pagination.total,
        onChange: (page) => console.log(page),
      }}
      dateFormatter="string"
      rowSelection={{
        // 自定义选择项参考: https://ant.design/components/table-cn/#components-table-demo-row-selection-custom
        // 注释该行则默认不显示下拉选项
        // selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
        defaultSelectedRowKeys: [],
        columnWidth:30
      }}
      tableAlertRender={({
        selectedRowKeys,
        selectedRows,
        onCleanSelected,
      }) => {
        console.log(selectedRowKeys, selectedRows);
        if(selectedRowKeys)
          setSelectedRowKeys(selectedRowKeys);
        return (
          <Space size={10}>
            <span>
              已选 {selectedRowKeys.length} 项<br />
              <a style={{ marginInlineStart: 8 }} onClick={onCleanSelected}>
                取消
              </a>
            </span>
            <Space size={5}>
              评委分组
              <Select
                // placeholder="请选择选择栏目"
                onChange={(value)=>setSelectedGroupid(value)}
                value={selectedGroupid}
                options={judgegroupList}
                // allowClear
                // style={{width:"50%"}}
              />
              <Button type="primary" size="small" onClick={()=>{updateJudgeToGroup(selectedRowKeys)}} disabled={selectedGroupid?false:true}>提交</Button>
            </Space>
            <Space size={10}>
              评选阶段
              <Select
                // placeholder="请选择选择栏目"
                onChange={(value)=>setSelectedLevelStatus(value)}
                value={selectedLevelStatus}
                options={levelStatusList}
                // allowClear
                // style={{width:"50%"}}
              />
              <Button type="primary" size="small" onClick={()=>{updateLevelStatus(selectedRowKeys)}} disabled={selectedLevelStatus?false:true}>提交</Button>
            </Space>
            <Space size={10}>
              获奖结果
              <Select
                // placeholder="请选择选择栏目"
                onChange={(value)=>setSelectedReward(value)}
                value={selectedReward}
                options={rewardList}
                // allowClear
                // style={{width:"50%"}}
              />
              <Button type="primary" size="small" onClick={()=>{updateReward(selectedRowKeys)}} disabled={selectedReward?false:true}>提交</Button>
            </Space>


          </Space>
        );
      }}
      tableAlertOptionRender={() => {

      }}
      toolBarRender={() => [
        <Upload
          name="uploadfile"
          // listType="picture-card"
          // className="avatar-uploader"
          showUploadList={false}
          action={AppConfig.INTER_IMPORT_CASE + localStorage.getItem('token')}
          // beforeUpload={beforeUpload}
          onChange={handleChange}
        >
        <Button
          key="button"
          icon={<UploadOutlined />}
          style={{backgroundColor:"rgb(152,100,201)", borderColor:"rgb(152,100,201)"}}
          onClick={async() => {
            // actionRef.current?.reload();
            // setRowInfo({});
            // await waitTime(100);
            // setShowModal(true)
          }}
          type="primary"
          loading={loading}
        >
          导入
        </Button>
        </Upload>,
        // <Button
        //   key="button"
        //   icon={<DownloadOutlined />}
        //   style={{backgroundColor:"rgb(5,168,137)"}}
        //   onClick={async() => {
        //     downList();
        //     // downloadExcel("案例管理", columns, tablesData, "", []);
        //   }}
        //   type="primary"
        // >
        //   导出
        // </Button>,
        // <Button
        //   key="button"
        //   icon={<DownloadOutlined />}
        //   style={{backgroundColor:"rgb(5,168,137)"}}
        //   onClick={async() => {
        //     downRadar();
        //     // downloadExcel("案例管理", columns, tablesData, "", []);
        //   }}
        //   type="primary"
        // >
        //   导出雷达图
        // </Button>,
        // <Button
        //   key="button"
        //   icon={<DownloadOutlined />}
        //   style={{backgroundColor:"rgb(5,168,137)"}}
        //   onClick={async() => {
        //     downLogoList();
        //     // downloadExcel("案例管理", columns, tablesData, "", []);
        //   }}
        //   type="primary"
        // >
        //   导出参赛单位logo
        // </Button>,
        <Dropdown
          key="tablemenu"
          menu={{
            onClick: onDownload,
            items: [
              {
                icon: <TableOutlined />,
                label: '导出表格数据',
                key: 'table',
              },
              {
                icon: <RadarChartOutlined />,
                label: '导出雷达图',
                key: 'radar',
              },
              {
                icon: <CopyrightOutlined />,
                label: '导出参赛单位logo',
                key: 'case',
              },
              {
                icon: <VideoCameraOutlined />,
                label: '导出附件视频',
                key: 'video',
              },
              {
                icon: <FilePdfOutlined />,
                label: '导出附件PDF',
                key: 'pdf',
              },
              {
                icon: <PictureOutlined />,
                label: '导出附件图片',
                key: 'pic',
              },
            ],
          }}
        >
          <Button type="primary" icon={<DownloadOutlined />} style={{backgroundColor:"rgb(5,168,137)"}}>
            导出<EllipsisOutlined />
          </Button>
        </Dropdown>,

      ]}
    />

    <EditModel
      showAddtc={showModal}
      modalinfo={{}}
      levellist={levellist}
      rewardList={rewardList}
      statuslist={statuslist}
      rowInfo={rowInfo}
      closeAddtc={closeModal}
      refreshList={()=>{actionRef.current?.reload();}}
    />

    <ScoreDetailModel
      showModal={showScoreDetail}
      levelEnum={levelEnum}
      rowInfo={rowInfo}
      closeModal={closeModal}
    />

    </div>
  );
};